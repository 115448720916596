import React from 'react';
import { usePermissions } from 'react-admin';
import { MerchantsInfo } from './components/MerchantsInfo';
import { PaymentMethods } from './components/PaymentMethods';
import { CampaignsInfo } from './components/CampaignsInfo';
import CardPayment from './components/CardPayment';

const Form = ({ isCreate }) => {
  const { permissions } = usePermissions();
  return (
    <>
      <MerchantsInfo />
      {permissions === 'superadmin' && <CampaignsInfo />}
      <PaymentMethods isCreate={isCreate} />
      {
        (!isCreate && permissions === 'superadmin') && (
          <CardPayment />
        )
      }
    </>
  );
};


export default Form;

