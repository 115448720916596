import React, { useEffect, useState } from 'react';
import Card from '../../Components/Card/Card';
import {
  useDataProvider,
  useNotify,
  usePermissions,
  useRedirect,
  useResourceContext,
} from 'react-admin';
import { InputNumberMP } from '../../Components/Input/Input';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { copyText } from '../../utils/helpers';
import BackButton from '../../Components/Button/BackButton';
import { NewCardToken } from '../../Components/Card/CardToken';
import styles from './Create.module.css';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CreateHeader from '../../Components/Form/CreateHeader';

const isTest = process.env.REACT_APP_DEVELOPMENT === 'true';

const generateObjectToken = (currencies) => {
  const result = [];
  for (const key in currencies) {
    if (currencies[key].enabled) {
      result.push(currencies[key].ticker);
    }
  }
  return result;
};

const Create = () => {
  const dataProvider = useDataProvider();
  const [merchantMe, setMerchantMe] = useState(null);
  const [link, setLink] = useState(null);

  useEffect(() => {
    try {
      dataProvider.getMerchantMe().then(({ data }) => {
        setMerchantMe(data);
      });
    } catch {
      console.log('errors merchant me');
    }
  }, []);

  const handleCopyLink = () => {
    copyText(link);
  };

  return (
    <Box
      sx={{
        padding: '42px 21px 0px 21px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: '100%' }}>
        {merchantMe && !link && (
          <CreatePaymentRequest merchantMe={merchantMe} setLink={setLink} />
        )}
        {link && (
          <>
            <BackButton />
            <Card
              title={'Link de pago'}
              sx={{ display: 'flex', alignItems: 'center', gap: 3 }}
            >
              {/* <Typography sx={{ fontSize: '14px', color: 'rgba(0,0,0,.3)', textOverflow: 'ellipsis' }}>{link}</Typography> */}
              {/* <IconButton aria-label="copy">
                <ContentCopyRoundedIcon />
              </IconButton> */}
              <TextField
                size='small'
                value={link}
                disabled
                sx={{ width: '300px' }}
              />
              <Button variant='text' onClick={handleCopyLink}>
                Copiar Link
              </Button>
            </Card>
          </>
        )}
      </Box>
    </Box>
  );
};

const CURRENCIES = [
  {
    name: 'ARS',
    value: 'ARS'
  },
  {
    name: 'USD',
    value: 'USD'
  }
];

const CreatePaymentRequest = ({ merchantMe, setLink }) => {
  const { permissions } = usePermissions();
  const [currency, setCurrency] = useState('ARS');
  const [amount, setAmount] = useState('');
  const [tokensPayment, setTokensPayment] = useState([]);
  const [reference, setReference] = useState('');
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const resource = useResourceContext();

  const handleChange = ({ target }) => {
    const value = target.value.replace(/[.,]/g, '');
    const testValue = /^\d*\.?\d+$/.test(value);

    if ((testValue && parseInt(value) >= 0) || value === '') {
      const formattedValue =
        value !== '' ? parseInt(value).toLocaleString('es-AR') : '';
      setAmount(formattedValue);
    }
  };
  const notify = useNotify();
  const handlePressCreateLink = () => {
    setLoading(true);
    const amountInt = parseInt(amount.replace(/\./g, ''));
    const url = isTest
      ? 'https://develop.pagar.simplefi.tech'
      : 'https://pagar.simplefi.tech';
    const paramTokens = tokensPayment;

    const data = {
      amount: amountInt,
      currency,
      coins: paramTokens,
      reference: { data: reference },
    };

    dataProvider
      .create('payment_requests', { data })
      .then(({ data }) => {
        const link = `${url}/${merchantMe.slug}/payment/${data.id}`;
        setLink(link);
      }).catch((err) => {
        notify('No se puede crear una orden - Revisá la configuración de tu negocio', { type: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickCard = (currency) => {
    if (tokensPayment?.includes(currency)) {
      setTokensPayment(tokensPayment.filter((c) => c !== currency));
    } else {
      setTokensPayment([...tokensPayment, currency]);
    }
  };

  const goBack = () => {
    redirect(`/${resource}`);
  };

  return (
    <div className={styles.container}>
      <CreateHeader goBack={goBack} label='Nuevo Cobro' help={true} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ fontSize: '16px', fontWeight: '600', ml: 1, mb: 2 }}>
          {isTest && '(Test)'}
        </Typography>
      </Box>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '15px 0px',
          width: '100%'
        }}
        sxHeader={{ backgroundColor: '#FDFDFD', border: '1px solid #E7E7E7' }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '375px',
            height: '170px',
            justifyContent: 'center',
            boxSizing: 'border-box'
          }}>
            <Box sx={{
              height: '30px',
              display: 'flex',
              alignItems: 'flex-start'
            }}>
              <Typography sx={{justifySelf: 'flex-start', fontSize: '.8em', textTransform: 'uppercase', fontWeight: '600', }}>Ingrese el monto</Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              boxSizing: 'border-box'

            }}>

              <InputNumberMP
                sx={{
                  // alignSelf: 'center',
                  justifySelf: 'center'
                }}
                label={'Monto'}
                value={amount}
                onChange={handleChange}
                placeholder={'0.00'}
              />
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currency}
                variant='outlined'
                label=""
                sx={{ border: 'none', '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
                onChange={(e) => setCurrency(e.target.value)}
              >{
                  CURRENCIES.map((curr) => <MenuItem value={curr.value}>{curr.name}</MenuItem>)
                }
              </Select>
            </Box>
          </Box>
        
            
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '10px',
            width: '375px'
          }}
        >
          <p className={styles.referenceLabel}>
            Nombre/ Descripcion del producto (opcional)
          </p>
          <TextField
            value={reference}
            variant="standard"
            fullWidth
            onChange={({ target }) => setReference(target.value)}
          />
        </Box>
      </Card>

      {/* {permissions !== 'operator' && (
        <Card
          title={'Medios de pago disponibles'}
          sxTitle={{ fontSize: '20px', lineHeight: '27px', fontWeight: 600 }}
          sx={{
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap',
            padding: '0 0 12px 30px',
          }}
          sxHeader={{ backgroundColor: '#FDFDFD', border: '1px solid #E7E7E7' }}
        >
          {merchantMe.currencies?.map((currency, index) => {
            if (currency.enabled) {
              return (
                // <CardToken key={index} token={currency} handleClick={() => handleClickCard(currency)} showChain enabled={currency.enabled} />
                <NewCardToken
                  key={index}
                  token={currency}
                  handleClick={handleClickCard}
                  currency={currency}
                  showChain
                  enabled={currency.enabled}
                />
              );
            }
          })}
        </Card>
      )} */}
      <ActionsForm
        onPress={handlePressCreateLink}
        loading={loading}
        disabled={amount === ''}
      />
    </div>
  );
};

const ActionsForm = ({ onPress, loading, disabled }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        variant='contained'
        onClick={onPress}
        size='small'
        disabled={loading || disabled}
        sx={{ fontWeight: '600' }}
      >
        {loading ? 'Cargando...' : 'Crear'}
      </Button>
    </Box>
  );
};

const PaymentTokenBoolean = ({ token, checked, onChange }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
      <Switch
        label=''
        sx={{ '& .MuiFormHelperText-root': { display: 'none' } }}
        checked={checked}
        onChange={({ target }) => onChange(target.checked, token.coin)}
      />
      <img
        src={token.logo}
        style={{
          width: '24px',
          height: '24px',
          filter: !checked && 'grayscale(100%)',
        }}
      />
      <Typography
        sx={{
          fontSize: '15px',
          fontWeight: '600',
          color: 'rgba(0,0,0,.7)',
          ml: 1,
        }}
      >
        {token.name}
      </Typography>
      {token.blockchain_logo && (
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            color: 'rgba(0,0,0,.7)',
            ml: '6px',
          }}
        >
          {token.blockchain}
        </Typography>
      )}
    </Box>
  );
};

export default Create;
