import { Box, Button, IconButton, Typography } from '@mui/material';
import Card from '../../../Components/Card/Card';
import InputLabel from '../../../Components/Input/InputLabel';
import InputText from '../../../Components/Input/InputText';
import { LinkCheckout } from '../../Settings/Components/LinkCheckout';
import DownloadIcon from '@mui/icons-material/Download';
import { createQR } from '../../../utils/helpers';
import { useState } from 'react';
import { required, useRecordContext } from 'react-admin';
import Modal from '../../../Components/Modal/Modal';
import QRCodeComponent from '../../../Components/QRCode/QRCodeComponent';

export const MerchantsInfo = () => {
  const record = useRecordContext();
  const [openModal, setOpenModal] = useState(false);

  const urlEnv =
    process.env.REACT_APP_DEVELOPMENT === 'true'
      ? 'https://develop.pagar.simplefi.tech'
      : 'https://pagar.simplefi.tech';
  const urlQRCode = `${urlEnv}/${record?.slug}/static`;

  const handleClickDownload = () => {
    const qrcode = createQR(urlQRCode);
    var url = qrcode.createDataURL(8, 1);
    var link = document.createElement('a');
    link.download = `${record.name}-QR.png`;
    link.href = url;
    link.width = '300px';
    link.height = '300px';
    link.click();
  };

  return (
    <Card title={'Datos del negocio'}
      border
      sx={{
        padding: '21px 21px 12px 36px',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '20px',
        width: '100%'
      }}>
      <InputLabel

        input={<InputText source={'name'} label={''} validate={[required()]} />}
        label={'Nombre *'}
      />
      <InputLabel


        input={<InputText source={'notification_url'} label={''} />}
        label={'URL de Notificaciones'}
      />
      <InputLabel


        input={<InputText source={'direction'} label={''} />}
        label={'Dirección'}
      />
      <InputLabel
        input={<InputText source={'google_maps_link'} label={''} />}


        label={'Google Maps URL'}
      />

      <LinkCheckout />
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', gap: 3, alignItems: 'center' }}>

        <Button
          variant='contained'
          onClick={handleClickDownload}
          startIcon={<DownloadIcon />}
        >
          Descargar QR
        </Button>
        <div>
          <Button
            variant='outlined'
            onClick={() => setOpenModal(true)}
          >
            Ver QR
          </Button>
        </div>
      </Box>
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        <QRCodeComponent url={urlQRCode} />
      </Modal>
    </Card>
  );
};
