import { BooleanInput, required, TextInput, useDataProvider, useRecordContext } from 'react-admin';
import Card from '../../../Components/Card/Card';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { isAddress } from 'web3-validator';

const requestHeaders = {
  pagination: { page: 1, perPage: 100 },
  sort: { field: 'rank', order: '' },
};

const orderCoins = (chains) => {
  return chains.sort((a, b) => {
    if (a.chain_id === 0) return -1; //BTC va primero
    if (b.chain_id === 0) return 1;
    if (a.chain_id === -1 && b.chain_id !== 0) return -1; // El BCH va después de BTC
    if (b.chain_id === -1 && a.chain_id !== 0) return 1;
    return a.chain_id - b.chain_id; // El resto se ordena de menor a mayor
  });
};

const groupedChains = (data) => {
  if (!data) return;
  return orderCoins(Object.values(data.reduce((acc, current) => {
    const { chain_id, chain_logo, chain_name, ...rest } = current;

    if (!acc[chain_id]) {
      acc[chain_id] = {
        chain_id,
        chain_name,
        chain_logo,
        currencies: []
      };
    }

    acc[chain_id].currencies.push({ chain_id, ...rest });

    return acc;
  }, {})));
};

const formatCurrencies = (data) => {
  return data.map((item) => ({
    address_needed: item.address_needed,
    chain_id: item.chain_id,
    ticker: item.ticker,
    chain_logo: item.chain.logo,
    chain_name: item.chain.name,
    currency_logo: item.logo
  }));
};

export const PaymentMethods = ({ isCreate }) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const [chains, setChains] = useState(null);

  const { data, isFetching } = useQuery(['getCurrencies'], async () => {
    try {
      const response = await dataProvider.getCurrencies(requestHeaders);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }, {
    onError: () => console.log('Error currencies'),
    enabled: !!isCreate
  });

  useEffect(() => {
    if (isCreate && data) {
      setChains(groupedChains(formatCurrencies(data.results)));
      return;
    }
    setChains(groupedChains(record?.currencies));
    return;
  }, [data, record]);


  return (
    <Card border isLoading={isFetching} sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }} title="Tokens">
      <DefaultAddressEVM />
      {
        chains?.map((item, key) => (
          <ChainItemSection item={item} key={`item-section-chain${key}`} currencies={record?.currencies ? record.currencies : formatCurrencies(data.results)} />
        ))
      }
    </Card>
  );
};

const DefaultAddressEVM = () => {
  const defaultAddress = useWatch({ name: 'default_evm_address' });

  const checkIsAddress = () => {
    if (defaultAddress === '' || !defaultAddress) return undefined;

    const isValidAddress = isAddress(defaultAddress);
    if (!isValidAddress) {
      return 'Debes ingresas una address valida';
    }
    return undefined;
  };
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '9px' }}>
      <Typography fontWeight={'600'} fontSize={'14px'}>Address por defecto para EVMs</Typography>
      <TextInput sx={{ width: '60%' }} label={'Address'} source={'default_evm_address'} validate={[checkIsAddress]} />
    </Box>
  );
};

const ChainItemSection = ({ item, currencies }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
      <Box sx={{ display: 'flex', padding: '3px 9px', width: '100%', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: '12px', width: '190px', overflow: 'visible', flexWrap: 'nowrap' }}>
          <img src={item.chain_logo} width={'21px'} height={'21px'} />
          <Typography fontWeight={'600'}>{item.chain_name}</Typography>
        </Box>
        <Typography fontWeight={'600'}>Address</Typography>
        <Typography fontWeight={'600'}>Habilitado</Typography>
      </Box>
      {
        item.currencies?.map((curr, key) => (
          <CurrencyMerchant item={curr} key={`item-currency-${key}`} currencies={currencies} />
        ))
      }
    </Box>
  );
};

const findIndexItem = (chain, ticker, currencies) => {
  return currencies.findIndex(el => (el.chain_id === chain && el.ticker.toLowerCase() === ticker.toLowerCase()));
};

const CurrencyMerchant = ({ item, currencies }) => {
  const index = findIndexItem(item.chain_id, item.ticker, currencies);
  const isEnabled = useWatch({ name: `currencies[${index}].enabled` });
  const address = useWatch({ name: `currencies[${index}].address` });
  const defaultAddress = useWatch({ name: 'default_evm_address' });

  const checkIsAddress = () => {
    if (!address && defaultAddress) return undefined;

    const isValidAddress = isAddress(address);
    if (!isValidAddress) {
      return 'Debes ingresas una address valida';
    }
    return undefined;
  };

  console.log('value', defaultAddress, address);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '9px 12px', borderRadius: '12px', border: '1px solid #e4e4e4', backgroundColor: '#f8f8f8' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '9px', width: '210px' }}>
        <img src={item.currency_logo} width={'24px'} height={'24px'} />
        <Typography fontWeight={'600'} fontSize={'14px'}>{item.ticker}</Typography>
      </Box>
      {
        (item.address_needed && isEnabled) ? (
          <TextInput
            defaultValue={defaultAddress}
            source={`currencies[${index}].address`}
            label={''}
            size='small'
            validate={[required(), checkIsAddress]}
            sx={{
              '& .MuiFormHelperText-root': {
                display: 'none'
              },
              direction: 'rtl'
            }}
          />
        ) : <div style={{ height: '44px' }}></div>
      }
      <BooleanInput
        source={`currencies[${index}].enabled`}
        label={''}
        helperText={null}
        sx={{
          '& .MuiFormHelperText-root': {
            display: 'none'
          }
        }}
      />

      <TextInput style={{ display: 'none' }} defaultValue={item.ticker} source={`currencies[${index}].ticker`} />
      <TextInput style={{ display: 'none' }} defaultValue={item.chain_id} source={`currencies[${index}].chain_id`} />

    </Box>
  );
};