import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import dayjs from 'dayjs';
import { calculateDate } from '../../utils/helpers';

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const PriceDateField = (props) => {
  const record = useRecordContext(props);

  if (record) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontSize: '15px', color: '#000000', fontWeight: 600, lineHeight: '18px' }}>{Math.round(record.amount ?? 0).toLocaleString('es-AR')} {record.currency ?? 'ARS'}</Typography>
        <Typography sx={{ fontSize: '14px', color: '#999' }}>{calculateDate(record.date_created)?.fromNow()}</Typography>
      </Box>
    );
  }
};

export default PriceDateField;