
import Card from '../../Components/Card/Card';
import { ChartLineComponent } from './Components/ChartLineComponent';
import { ChartPieComponent } from './Components/ChartPieComponent';
import { Fragment, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DashbordFilters } from './Components/DashboardFilters';
import { Box, Button, MenuItem, Skeleton, TextField, Typography } from '@mui/material';
import { useDataProvider, usePermissions } from 'react-admin';
import { useQuery } from 'react-query';
import { MdHomeWork } from 'react-icons/md';
import { useCurrencies } from '../../utils/helpers/useCurrencies';
import { ItemList } from '../Reports/List';
import { Spinner } from '../../Components/Spinner/Spinner';


export const DashboardList = () => {
  const [filters, setFilters] = useState({
    'from_date': dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    'to_date': dayjs().format('YYYY-MM-DD')
  });
  const [state, setState] = useState(true);
  const [merchant, setMerchant] = useState(null);
  const { permissions } = usePermissions();
  const { currencies, loading, error } = useCurrencies();

  const dataProvider = useDataProvider();
  const { data } = useQuery(['merchants'],() => dataProvider.getMerchants(), {
    enabled: permissions === 'superadmin'
  } );

  const { refetch, data: {data: reports} = {}, isFetching } = useQuery(['reports'],() => dataProvider.getResource('reports',filters), {
    enabled: state
  } );

  useEffect(() => {
    refetch();
  },[filters]);
  
  const selectMerch = (e) => {
    setMerchant(e.target.value);
  };

  return (
    <>
      {
       
        permissions === 'superadmin' && !merchant ? (
          <MerchantMenu selectMerch={selectMerch} permissions={permissions} merchants={data?.data?.results} />
        ) : (
       
   
          <Box key="dashboard-Box" sx={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'auto'
          }}>
     
            <Box mt={2} sx={{width: 'auto', marginX: '100px', gap: '10px', display: 'flex', flexDirection: 'column'}} title="Dashboard">
              <Typography sx={{
                fontSize: '2.1em',
                fontWeight: '700',
                color: 'primary',
                alignSelf: 'flex-start'
              }}>Dashboard</Typography>
    
              <DashbordFilters  initialValue={data?.data?.results?.find(item => item.id === merchant)} merchants={data} filters={filters} setFilters={setFilters} />
              <Card
                title=""
                sx={{
                  gap: '10px',
                  display: 'flex',
                  flexWrap: 'wrap', 
                  height:'auto', 
                  flexDirection : 'row',
                  justifyContent: 'flex-start', 
                  alignItems: 'center', 
                  width: 'auto',
                  padding: '12px',
                  minWidth: '1200px'
                }}>
                <Button variant={state ? 'contained' : 'outlined'} onClick={() => setState(true)} sx={{ fontWeight: '600'}}>Reporte</Button>
                <Button variant={!state ? 'contained' : 'outlined'} onClick={() => setState(false)} sx={{ fontWeight: '600'}}>Dashboard</Button>
              </Card>
              {
                state ? (
                  isFetching ? (
                    <Spinner />
                  ) : (
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                      <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', flex: 1, gap: 4, width: '100%' }}>
                          <Card title={'Cobros en pesos (ARS)'} sx={{ padding: '0px 21px 12px 18px' }}>
                            <Typography sx={{ fontSize: '24px', fontWeight: '600', textAlign: 'center' }}>$ {reports?.total_ars}</Typography>
                          </Card>
                          <Card title={'Pagos aprobados'} sx={{ padding: '0px 21px 12px 18px' }}>
                            <Typography sx={{ fontSize: '24px', fontWeight: '600', textAlign: 'center' }}>{reports?.payment_requests_approved}</Typography>
                          </Card>
                        </Box>
                        <Box sx={{ display: 'flex', flex: 1 }}>
                          <Card title={'Cobros por token'} sx={{ padding: '0px 21px 12px 18px', width: '100%' }} sxHeader={{ width: '100%' }}>
                            {currencies?.map((token)=> {
                              return (
                                <ItemList
                                  key={token?.id}
                                  icon={token?.logo}
                                  title={token?.name}
                                  chain={token?.chain?.name}
                                  valueARS={Math.trunc(reports?.totals_per_coin?.[token?.ticker]?.ars)}
                                  valueCrypto={reports?.totals_per_coin?.[token?.ticker]?.crypto}
                                  slice={4}
                                />
                              );
                            })}
                          </Card>
                        </Box>
                      </Box>
                    </Box>
                  )
                ) : (
                  <Fragment>
                    <Card 
                      border
                      sxHeader={{
                        width: 'auto',
                      }}
                      title="Total ventas"
                      sx={{
                        gap: '100px',
                        display: 'flex',
                        flexWrap: 'wrap', 
                        height:'auto', 
                        flexDirection : 'row',
                        justifyContent: 'flex-start', 
                        alignItems: 'center', 
                        width: 'auto',
           
                      }}>
                      <ChartLineComponent filters={filters} options={{plugins: {legend: {display: false}}}} />
                    </Card>
                    <Card sxHeader={{
                      width: 'auto', 
                      maxWidth: '700px',

                    }} sx={{width: 'auto'}} border title="Total por coins">
                      <ChartPieComponent filters={filters} />
                    </Card>
                  </Fragment>
                )}
            </Box>
    
          </Box>
        )
      
      }</>
  );
};



export const MerchantMenu = ({ merchants, permissions, selectMerch }) => {

  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <MdHomeWork size="300px" />
      <Typography sx={{
        fontSize: '1.1em',
        fontWeight: '600',
        
      }}> Selecciona un negocio para visualizar el dashboard</Typography>
      {
        merchants ? (
          <TextField
            select
            sx={{
              width: '500px'
            }}
            onChange={selectMerch}
            variant="outlined">
            {
              merchants?.map((item, index) => (
                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
              ))
            }
          </TextField>
        ) : (
          <Skeleton height="50px" />
        )
      }
    </Box>
  );
};