import React from 'react';
import { EditBase, usePermissions, useRedirect, useResourceContext } from 'react-admin';
import SimpleFormCustom from '../../Components/Form/SimpleFormCustom';
import Form from './Form.jsx';
import CreateHeader from '../../Components/Form/CreateHeader';

const Edit = () => {
  const { permissions } = usePermissions();
  const redirect = useRedirect();
  const resource = useResourceContext();

  const goBack = () => {
    redirect(`/${resource}`);
  };

  return (
    <EditBase mutationMode='pessimistic'>
      <SimpleFormCustom
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 'auto' }}
        hasDelete={permissions === 'superadmin'}
        messageDelete={{
          confirmContent: '¿Estas seguro de eliminar este Negocio?',
          confirmTitle: 'Eliminar negocio'
        }}
        header={<CreateHeader goBack={goBack} label='' isEdit={true} />}
      >
        <Form />
      </SimpleFormCustom>
    </EditBase>
  );
};

export default Edit;