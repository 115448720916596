import { Admin, CustomRoutes, Resource } from 'react-admin';
import { QueryClient } from 'react-query';
import dataProvider, { instance } from './dataProvider';
import LoginScreen from './Resource/Login/LoginScreen';
import authProvider from './authProvider';
import Actividad from './Resource/Actividad';
import { useEffect } from 'react';
import './styles.css';
import { theme } from './Theme';
import Settings from './Resource/Settings';
import LayoutCustom from './Components/Layout/LayoutCustom/LayoutCustom';
import User from './Resource/User';
import dayjs from 'dayjs';
import Payment from './Resource/Payment';
import Merchants from './Resource/Merchants';
import Reports from './Resource/Reports';
import NetworkLink from './Resource/NetwrokLink/NetworkLink';
import { Route } from 'react-router-dom';
import { PERMISSIONS } from './assets/constants/PERMISSIONS';
import i18nProvider from './i18nProvider';
require('dayjs/locale/es');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    },
  },
});

const hasPermissionForResource = (permissions, resource) => {

};

export const App = () => {
  useEffect(() => {
    dayjs.locale('es');
    const token = localStorage.getItem('token');
    if (token) {
      instance.defaults.headers.common['access-token'] = token;
    }
  }, []);

  return (
    <Admin
      i18nProvider={i18nProvider}
      queryClient={queryClient}
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={LoginScreen}
      requireAuth
      disableTelemetry
      theme={theme}
      layout={LayoutCustom}
    >
      {permissions => {
        const config = PERMISSIONS[permissions] || { resources: [], customRoutes: [] };

        const resources = config.resources.map(resource => (
          <Resource
            key={resource.name}
            name={resource.name}
            options={{ label: resource.label, isSeparator : resource?.isSeparator }}
            {...resource.component}
          />
        ));

        const customRoutes = config.customRoutes.map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.component />}
          />
        ));

        return (
          <>
            {resources}
            <CustomRoutes>
              {customRoutes}
            </CustomRoutes>
          </>
        );
      }}
    </Admin>
  );
};
