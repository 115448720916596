import { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { USD_CURRENCY } from '../../assets/constants/TOKENS';

export const useCurrencies = () => {
  const dataProvider = useDataProvider();
  const [currencies, setCurrencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const { data } = await dataProvider.getList('currencies', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: '', order: '' },
        });
        console.log('currencies', data);
        setCurrencies([...data, USD_CURRENCY]);
        setLoading(false);
      } catch (error) {
        console.log('Error fetching data:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchCurrencies();
  }, [dataProvider]);

  return { currencies, loading, error };
};