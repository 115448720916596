import { useNotify, useRecordContext } from 'react-admin';
import { copyText } from '../../../utils/helpers';
import { Box, IconButton, Typography } from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { FiCopy } from 'react-icons/fi';

export const LinkCheckout = ({ sx }) => {
  const record = useRecordContext();
  const notify = useNotify();

  const endpoint =
    process.env.REACT_APP_DEVELOPMENT === 'true'
      ? 'https://develop.pagar.simplefi.tech'
      : 'https://pagar.simplefi.tech';
  const link = `${endpoint}/${record?.slug}`;

  const handleCopy = () => {
    copyText(link);
    notify('Dirección copiada al portapapeles', { type: 'success' });
  };

  const handleOpenClick = () => {
    window.open(link, '_blank');
  };

  if (record) {
    return (
      <Box sx={{
        minHeight: '80px',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
        ...sx
      }}>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '19px',
            fontWeight: 600,
            color: '#000000',
          }}
        >
          Link checkout
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography
            sx={{
              color: '#449AFF',
              fontSize: '14px',
              lineHeight: '19px',
              fontWeight: 600,
              cursor: 'pointer',
            }}
            onClick={handleOpenClick}
          >
            {link}
          </Typography>
          <IconButton onClick={handleCopy}>
            <FiCopy size="15px" />
          </IconButton>
        </Box>
      </Box>
    );
  }
};