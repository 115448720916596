import React from 'react';
import styles from './CreateHeader.module.css';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton } from '@mui/material';
import { useRecordContext } from 'react-admin';

const CreateHeader = ({ goBack, help, label, backAction = 'true', isEdit, onlyEditText, sx }) => {
  const record = useRecordContext();
  return (
    <div className={styles.header} style={{ ...sx }}>
      <div className={styles.titleBack}>
        {backAction && <IconButton onClick={goBack}>
          <KeyboardArrowLeftOutlinedIcon />
        </IconButton>}
        {onlyEditText ? <p className={styles.title}>{label}</p> : <p className={styles.title}>{isEdit ? 'Editar: ' + record?.name : label}</p>}
      </div>
      {/* {help && <button className={styles.helpBt}>
        <HelpOutlineIcon />
        <span>Ayuda</span>
      </button>} */}
    </div>
  );
};

export default CreateHeader;