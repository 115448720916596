import React, { useEffect, useState } from 'react';
import { useDataProvider, useRecordContext, useNotify } from 'react-admin';
import Card from '../../../Components/Card/Card';
import { Box, FormControlLabel, Switch, TextField, Typography } from '@mui/material';
import Button from '../../../Components/Button/Button';

const CardPayment = () => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const [response, setResponse] = useState(null);
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    enabled: false,
    api_key: '',
    extra_charge: 0,
  });

  const getResourceMerchant = () => {
    if (!record?.id) return;
    dataProvider.getResource(`merchants/${record.id}/stripe_credentials`).then(response => {
      if (response.status === 200) {
        const { enabled, api_key, extra_charge } = response.data;
        setResponse({ enabled, api_key, extra_charge });
        setData({ enabled, api_key, extra_charge });
      }
    }).catch(() => {
      console.log('catch');
    });
  };

  const handleClickSave = async () => {
    setLoading(true);
    const params = {
      enabled: data.enabled,
      api_key: data.api_key !== '' ? data.api_key : response?.api_key,
      extra_charge: Number(data.extra_charge)
    };

    try {
      if (!record?.id) return;
      if (response) {
        dataProvider.put(`merchants/${record.id}/stripe_credentials`, { data: params }).then((e) => {
          notify('Credenciales guardadas con éxito', { type: 'success' });
        }).catch((e) => {
          if (e.status === 400 && e.body?.detail) {
            notify(e.body?.detail, { type: 'error' });
          }
        }).finally(() => {
          setLoading(false);
        });
      } else {
        dataProvider.post(`merchants/${record.id}/stripe_credentials`, params).then((data) => {
          const { enabled, api_key, extra_charge } = data.data;
          notify('Credenciales guardadas con éxito', { type: 'success' });
          setResponse({ enabled, api_key, extra_charge });
        }).catch((e) => {
          if (e.response.status === 400 && e.response?.data?.detail) {
            notify(e.response?.data?.detail, { type: 'error' });
          }
        }).finally(() => {
          setLoading(false);
        });
      }
    } catch (e) {
      setLoading(false);
      console.log('errr', e);
    }
  };

  useEffect(() => {
    getResourceMerchant();
  }, []);

  // useEffect(() => {
  //   const btnSave = document.querySelector('button[aria-label="Guardar"][type="submit"]');
  //   btnSave?.addEventListener('click', handleClickSave);

  //   return () => {
  //     btnSave?.removeEventListener('click', handleClickSave);
  //   };
  // }, [data]);

  const handleChangeApiKey = (e) => {
    const value = e.target.value;
    setData({ ...data, api_key: value });

  };

  const handleExtraCharge = (e) => {
    const value = e.target.value;
    if (value.length <= 3 && Number(value) >= 0) {
      setData({ ...data, extra_charge: value });
    }

  };

  const handleChecked = (e) => {
    setData({ ...data, enabled: e.target.checked });
  };

  const disabledBtn = data.api_key.length <= 5 || (!data.extra_charge && data.extra_charge !== 0) || loading;


  return (
    <Card title={'Pagos con tarjeta'} border sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
      <Box >
        <FormControlLabel control={<Switch checked={data.enabled} onChange={handleChecked} />} label="Habilitar pagos con tarjeta" />
      </Box>

      {
        data.enabled && (
          <Box sx={{ display: 'flex', gap: '15px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontWeight={'600'}>Stripe api key</Typography>
              <TextField value={data.api_key} placeholder='' onChange={handleChangeApiKey} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography fontWeight={'600'}>Porcentaje de recargo</Typography>
              <TextField pattern="^[+]?\d*\.?\d+$" value={data.extra_charge} placeholder='' onChange={handleExtraCharge} />
            </Box>
          </Box>
        )
      }

      <div>
        <Button
          disabled={disabledBtn}
          onClick={handleClickSave}
        >Guardar</Button>
      </div>
    </Card>
  );
};

export default CardPayment;