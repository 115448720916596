import React from 'react';
import { Box, Modal as ModalMUI } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#fff',
  boxShadow: 24,
  borderRadius: '.25em',
  padding: '12px'
};

const Modal = ({ children, open, handleClose }) => {
  return (
    <ModalMUI
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        {children}
      </Box>
    </ModalMUI>
  );
};

export default Modal;