import { Box } from '@mui/material';
import React from 'react';
import { DeleteButton, SimpleForm, Toolbar } from 'react-admin';
import SaveButton from '../Button/SaveButton';

const SimpleFormCustom = ({
  children,
  sx,
  label,
  hasDelete,
  messageDelete,
  ...rest
}) => {

  return (
    <SimpleForm
      sx={{
        padding: '42px 21px 0px 21px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
      toolbar={
        <ToolbarCustom
          label={label}
          hasDelete={hasDelete}
          messageDelete={messageDelete}
        />
      }
      {...rest}
    >
      <div style={{ width: '100%', marginBottom: '20px' }}>
        {rest.header && rest.header}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Box sx={{ ...sx, width: '100%' }}>{children}</Box>
      </div>
    </SimpleForm>
  );
};

const ToolbarCustom = ({ label, hasDelete, messageDelete }) => {
  return (
    <Toolbar
      sx={{
        backgroundColor: '#F1F1F1',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '700px',
          display: 'flex',
          justifyContent: hasDelete ? 'space-between' : 'flex-end',
        }}
      >
        {hasDelete && (
          <DeleteButton
            confirmContent={messageDelete?.confirmContent}
            confirmTitle={messageDelete?.confirmTitle}
          />
        )}
        <SaveButton label={label} />
      </Box>
    </Toolbar>
  );
};

export default SimpleFormCustom;
